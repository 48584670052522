import React, { useRef, useEffect } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { NavLink } from "react-router-dom";
// import sailogo from '../../../../public/img/sailogo.jpg';
// import { HiDocumentAdd } from "react-icons/hi";
// import { HiUserAdd } from "react-icons/hi";
// import { HiBadgeCheck } from "react-icons/hi";
// import { HiDocumentText } from "react-icons/hi";
// import { HiViewGrid } from "react-icons/hi";
import { omsPrivateRoutee } from "./SidebarPrivateRoutes";
import { useSelector } from "react-redux";

function Sidebar({ toggleValue }) {
  const sidebarRef = useRef(null);
  console.log("jhgjjhgjhgj", omsPrivateRoutee);

  const userDetails = useSelector((state) => state.auth.userDetails);
  console.log("userrr detailssss", userDetails);

  const USER_TYPE = userDetails?.USER_TYPE;

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
  //       toggleValue();
  //     }
  //   }

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [toggleValue]);

  return (
    <>
      <div
        // ref={sidebarRef}
        className=" grid-rows-8 sm:grid-rows-9 lg:grid-rows-10 h-full sm:h-screen p-2 "
      >
        <div className="row-span-1 flex justify-between items-center border-b-2 border-[#DADADA] mb-8 ">
          <div className="flex flex-row items-center gap-2 py-2">
            <div className="">
              <img
                src="/img/sailogo.jpg"
                alt="Sai Computers"
                className=" h-18 sm:h-14 w-[60px]  rounded-full"
              />
            </div>
            <div className="text-white">
              <h1 className="text-xl font-bold w-20 ">OMS</h1>
              <h1 className="text-sm font-semibold ">Sai Computers Limited</h1>
            </div>
          </div>
          <button className="block sm:hidden" onClick={toggleValue}>
            <HiOutlineArrowLeft
              style={{ width: "50px", height: "20px", color: "white" }}
            />
          </button>
        </div>
        <ul className="row-span-8 mt-6 overflow-y-auto">
          {omsPrivateRoutee &&
            omsPrivateRoutee.length > 0 &&
            omsPrivateRoutee.map((route) => {
              if (route.role.includes(USER_TYPE)) {
                return (
                  <NavLink
                    key={route.uniqueKey}
                    to={route.pathName}
                    className={({ isActive }) =>
                      `block p-2  text-left sm:text-sm  font-semibold hover:text-blue-400 ${
                        isActive
                          ? `border-2 border-[#0DADF1] text-[#0DADF1]`
                          : "text-white"
                      } `
                    }
                    onClick={toggleValue}
                  >
                    <div className="flex flex-row items-center gap-2 text-lg">
                      {route.icon}
                      {route.name}
                    </div>
                  </NavLink>
                );
              }
              return null;
            })}
          {/* <li>
            <NavLink
              to={{
                pathname: "/user-registration",
              }}
              className={({ isActive }) =>
                `block p-2 text-left sm:text-sm text-gray-900 font-semibold hover:text-blue-400 ${
                  isActive ? "border-2 border-[#0DADF1] text-blue-400" : ""
                } `
              }
            >
              <div className="flex flex-row items-center gap-2 text-lg">
                <HiUserAdd />
                User registration
              </div>
            </NavLink>
          </li>
         */}
          {/* <li>
            <NavLink
              to={{
                pathname: "/dashboard",
              }}
              className={({ isActive }) =>
                `block p-2 text-left sm:text-sm text-gray-900 font-semibold hover:text-blue-400 ${
                  isActive ? "border-2 border-[#0DADF1] text-[#0DADF1]" : ""
                } `
              }
            >
              Dashboard
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to={{
                pathname: "/complaint-registration",
              }}
              className={({ isActive }) =>
                `block p-2 text-left sm:text-sm text-gray-900 font-semibold hover:text-blue-400 ${
                  isActive ? "border-2 border-[#0DADF1] text-blue-400" : ""
                }`
              }
            >
              <div className="flex flex-row items-center gap-2 text-lg">
                <HiDocumentAdd />
                Complaint Registration
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={{
                pathname: "/complaints",
              }}
              className={({ isActive }) =>
                `block p-2 text-left sm:text-sm text-gray-900 font-semibold hover:text-blue-400 ${
                  isActive ? "border-2 border-[#0DADF1] text-blue-400" : ""
                }`
              }
            >
              <div className="flex flex-row items-center gap-2 text-lg">
                <HiDocumentText />
                Complaints
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={{
                pathname: "/generate-planned-outage",
              }}
              className={({ isActive }) =>
                `block p-2 text-left sm:text-sm text-gray-900 font-semibold hover:text-blue-400 ${
                  isActive ? "border-2 border-[#0DADF1] text-blue-400" : ""
                }`
              }
            >
              <div className="flex flex-row items-center gap-2 text-lg">
                <HiDocumentAdd />
                Generate Planned Outage
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={{
                pathname: "/planned-outage",
              }}
              className={({ isActive }) =>
                `block p-2 text-left sm:text-sm text-gray-900 font-semibold hover:text-blue-400 ${
                  isActive ? "border-2 border-[#0DADF1] text-blue-400" : ""
                }`
              }
            >
              <div className="flex flex-row items-center gap-2 text-lg">
                <HiViewGrid />
                Planned Outages
              </div>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={{
                pathname: "/assign-crew",
              }}
              className={({ isActive }) =>
                `block p-2 text-left sm:text-sm text-gray-900 font-semibold hover:text-blue-400 ${
                  isActive ? "border-2 border-[#0DADF1] text-blue-400" : ""
                }`
              }
            >
              <div className="flex flex-row items-center gap-2 text-lg">
                <HiViewGrid />
                Complaint Follow Up
              </div>
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to={{
                pathname: "/confirmed-outages",
              }}
              className={({ isActive }) =>
                `block p-2 text-left sm:text-sm text-gray-900 font-semibold hover:text-blue-400 ${
                  isActive ? "border-2 border-[#0DADF1] text-blue-400" : ""
                }`
              }
            >
              Confirmed Outages
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to={{
                pathname: "/user-approval",
              }}
              className={({ isActive }) =>
                `block p-2 text-left sm:text-sm text-gray-900 font-semibold hover:text-blue-400 ${
                  isActive ? "border-2 border-[#0DADF1] text-blue-400" : ""
                }`
              }
            >
              <div className="flex flex-row items-center gap-2 text-lg">
                <HiBadgeCheck />
                User Approval
              </div>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
