import React from 'react'
import View from '../view'

function Unauthlayout() {
  return (
    <>
      <View />
    </>
  )
}

export default Unauthlayout
