import React from "react";
import AuthLayout from "./authLayout";
import Unauthlayout from "./unauthlayout";
import { useSelector } from "react-redux";

const AppLayout = () => {
  const token = useSelector((state) => state.auth.token);
  let isAuthenticated = token;
  // let isAuthenticated = true;

  return <>{isAuthenticated ? <AuthLayout /> : <Unauthlayout />}</>;
};

export default AppLayout;
