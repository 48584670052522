import React, { useState, useEffect } from "react";
import View from "../view";
import Sidebar from "../view/layout/components/Sidebar";
import Header from "../view/layout/components/Header";
import UserProfile from "../view/UserProfile/Component/UserProfile";

const AuthLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const toggleSidebar = () => {
    console.log("isopen", isOpen);
    console.log("toggle executed");
    setIsOpen(!isOpen);
  };

  const toggleProfile = () => {
     console.log('isopen' , isOpen)
     console.log('toggle executed')
    setOpenProfile(!openProfile);
  };

  const handleOverlayClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
    else if(openProfile)
      setOpenProfile(false)
      
  };
  

  return (
    <>
      <div className="font-custom h-screen   relative grid grid-cols-none sm:grid-cols-12  ">
        <div
          className={`absolute sm:relative sidebar sm:grid  sm:col-span-3 lg:col-span-2 h-full  w-4/6 sm:w-full bg-[#0E577A] border-r-2 border-[#DADADA] transition duration-700 z-40 ${
            isOpen ? "block" : "hidden sm:block"
          } `}
        >
          <Sidebar toggleValue={toggleSidebar} />
        </div>
        {(isOpen || openProfile) && (
          <div
            className="fixed inset-0 bg-white bg-opacity-0 z-30 "
            onClick={handleOverlayClick}
          ></div>
        )}
        <div className="  grid grid-rows-9 sm:col-span-9 lg:col-span-10 ">
          <div
            aria-hidden="false"
            className="row-span-1 border-b-2 border-[#DADADA] flex items-center justify-between sm:justify-end bg-[#0E577A] "
          >
            <Header
              toggleValue={toggleSidebar}
              toggleProfile={toggleProfile}
              className="profileIcon"
            />
          </div>
          <div className="row-span-8 bg-[#EFF4FF] ">
            <View />
          </div>
          <div
            className={`absolute z-30 right-2 mt-12 ${
              openProfile ? "block" : "hidden"
            } `}
          >
            <UserProfile toggleProfile={toggleProfile} />
          </div>
         
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
