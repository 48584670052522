import React, { useState } from "react";
import { HiMenu } from "react-icons/hi";
import { HiUserCircle } from "react-icons/hi";
import { useSelector } from "react-redux";

import { jwtDecode } from "jwt-decode";

function Header({ toggleValue, toggleProfile }) {
  const token = useSelector((state) => state.auth.token);
  const decodedToken = jwtDecode(token);
  return (
    <>
      <button className=" sm:hidden text-slate-200" onClick={toggleValue}>
        <HiMenu style={{ width: "30px", height: "30px" }} />
      </button>
      <div className="flex flex-row gap-2 items-center">
        <h2 className=" text-slate-200">{decodedToken.USER_NAME}</h2>
        <button className="mr-4  text-slate-200" onClick={toggleProfile}>
          <HiUserCircle style={{ width: "40px", height: "40px" }} />
        </button>
      </div>
    </>
  );
}

export default Header;
