import React from "react";

const omsPublicRoute = [
  {
    uniqueKey: "oms.sign-up",
    pathName: "/sign-up",
    componentName: React.lazy(() => import("../view/signup")),
  },
  {
    uniqueKey: "oms.sign-in",
    pathName: "/sign-in",
    componentName: React.lazy(() => import("../view/signin")),
  },
  // {
  //   uniqueKey: "oms.launch-oms",
  //   pathName: "/launch-oms",
  //   componentName: React.lazy(() => import("../view/launch")),
  // },
  {
    uniqueKey: "oms.forgot-password",
    pathName: "/forgot-password",
    componentName: React.lazy(() => import("../view/forgotPassword")),
  },
  {
    uniqueKey: "oms.otp",
    pathName: "/otp",
    componentName: React.lazy(() => import("../view/otp")),
  },
  {
    uniqueKey: "oms.privacy-policy",
    pathName: "/privacy-policy",
    componentName: React.lazy(() => import("../view/privacyPolicy")),
  },
];

export default omsPublicRoute;
