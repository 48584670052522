import React from "react";
import { HiBadgeCheck, HiDocumentAdd, HiDocumentText, HiOutlineClipboardCheck, HiViewGrid } from "react-icons/hi";
import { RiCalendarScheduleFill, RiUserFollowFill } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { MdContactSupport } from "react-icons/md";

export const omsPrivateRoutee = [
  /* {
    uniqueKey: "oms.dashboard",
    pathName: "/dashboard",
    name: "Dashboard",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],
    
    componentName: React.lazy(() => import("../../dashboard")),
  }, */
  {
    uniqueKey: "oms.complaint-registration",
    pathName: "/complaint-registration",
    name: 'Complaint Registration',
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],
    icon: <HiDocumentAdd />,
    componentName: React.lazy(() => import("../../complaintRegistration")),
  },
  {
    uniqueKey: "oms.complaints",
    pathName: "/complaints",
    name: 'Complaints',
    icon: <HiDocumentText />,
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],

    componentName: React.lazy(() => import("../../complaints")),
  },
  {
    uniqueKey: "oms.generate-planned-outage",
    pathName: "/generate-planned-outage",
    name: " Planned Outage Registration",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],
    icon: <RiCalendarScheduleFill />,
    componentName: React.lazy(() => import("../../generatePlannedOutages")),
  },
  {
    uniqueKey: "oms.planned-outage",
    pathName: "/planned-outage",
    name:"Planned Outages",
    icon: <HiViewGrid />,
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],


    componentName: React.lazy(() => import("../../plannedOutage")),
  },
  /* {
    uniqueKey: "oms.confirmed-outages",
    pathName: "/confirmed-outages",
    name: 'Confiremed Outages',
    icon: <HiDocumentText />,
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],

    componentName: React.lazy(() => import("../../confirmedOutages")),
  }, */
 
  // {
  //   uniqueKey: "oms.follow-up",
  //   pathName: "/follow-up",
  //   name: ' Follow Up',
  //   icon: <FiUsers />,
  //   role: ['SUPERADMIN' , 'DEPARTMENTAL'],

  //   componentName: React.lazy(() => import("../../complaintFollowUp")),
  // },

  // {
  //   uniqueKey: "oms.assigned-crew",
  //   pathName: "/assigned-crew",
  //   name: ' Assigned Crew',
  //   icon: <RiUserFollowFill />,
  //   role: ['SUPERADMIN' , 'DEPARTMENTAL'],

  //   componentName: React.lazy(() => import("../../CrewAssigned")),
  // },
  {
    uniqueKey: "oms.user-approval",
    pathName: "/user-approval",
    name: 'User Approval',
    role: ['SUPERADMIN'],
    icon: <HiOutlineClipboardCheck />,


    componentName: React.lazy(() => import("../../UserApproval")),
  },
  {
    uniqueKey: "oms.user-reject",
    pathName: "/all-approved-users",
    name: 'User Status',
    role: ['SUPERADMIN'],
    icon: <HiBadgeCheck />,


    componentName: React.lazy(() => import("../../approvedUser")),
  },
  {
    uniqueKey: "oms.support",
    pathName: "/all-support",
    name: 'Queries',
    role: ['SUPERADMIN'],
    icon: <MdContactSupport/>,


    componentName: React.lazy(() => import("../../Support")),
  }
];

// export default omsPrivateRoutee;

