import Axios from "axios";
const base_Url = "https://stage-oms.thesaicomputers.com/oms";
// const base_Url = "http://localhost:8089";

export function getDesignations() {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-designation`)
      .then((response) => {
        console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getUserAreaDeatils(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/user/get-user-profile-details`, config)
      .then((response) => {
        console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function closeRequest(token, body) {
  console.log("bodybody", body);
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/complaint/close-complaint`, body, config)
      .then((response) => {
        console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getUserTableForApproval(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  console.log("tokentoken", token);
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/user/approval-details`, config)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function rejectRequestForUser(token, user_id) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/user/reject-user`, { user_id }, config)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getOneDesginationDetail(desg) {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-desg-details/${desg}`)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getApprovedUsers(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/user/get-approved-rejected-users`, config)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getAllDistrictUnderDiscom(disocm) {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-ditrict-by-discom?DISCOM=${disocm}`)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getAllZoneUnderDistrict(DISTRICT_CODE) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/static/get-zone-by-ditrict?DISTRICT_CODE=${DISTRICT_CODE}`
    )
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getAllCircleUnderZone(ZONE_CODE) {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-circle-by-zone?ZONE_CODE=${ZONE_CODE}`)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getAllDivisionUnderCircle(CIRCLE_CODE) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/static/get-division-by-circle?CIRCLE_CODE=${CIRCLE_CODE}`
    )
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getAllSubDivUnderDiv(DIV_CODE, CIRCLE_CODE) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/static/get-subdivision-by-division?DIV_CODE=${DIV_CODE}&CIRCLE_CODE=${CIRCLE_CODE}`
    )
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getAllSubStationBySUbDiv(SUB_DIV_CODE, CIRCLE_CODE, DIV_CODE) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/static/get-substation-by-subdivision?SUB_DIV_CODE=${SUB_DIV_CODE}&CIRCLE_CODE=${CIRCLE_CODE}&DIV_CODE=${DIV_CODE}`
    )
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getAllFeedersUnderSubstation(
  SUBSTATION_CODE,
  CIRCLE_CODE,
  DIV_CODE,
  SUB_DIV_CODE
) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/static/get-feeder-by-substation?SUBSTATION_CODE=${SUBSTATION_CODE}&SUB_DIV_CODE=${SUB_DIV_CODE}&CIRCLE_CODE=${CIRCLE_CODE}&DIV_CODE=${DIV_CODE}`
    )
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function approveFactArea(
  values,
  areaDetail,
  userId,
  desgDetails,
  token,
  id
) {
  let body = {
    id: id,
    user_id: userId,
    AREA_LEVEL: areaDetail.AREA_LEVEL,
    AREA_CODE: areaDetail.AREA_CODE,
    AREA: areaDetail.AREA,
    APPROVED_STATUS: "APPROVED",
    DESG: desgDetails?.DESG,
    DISCOM: values.DISCOM,
    DISTRICT_CODE: values.DISTRICT,
    ZONE_CODE: values.ZONE,
    CIRCLE_CODE: values.CIRCLE,
    DIV_CODE: values.DIVISION,
    SUB_DIV_CODE: values.SUB_DIVISION,
    SUBSTATION_CODE: values.SUB_STATION,
  };

  let config = {
    headers: {
      Authorization: token,
    },
  };
  console.log("approval fdatat", body);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/user/assign-fact-area`, body, config)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getPlanOutageMatrixData() {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-plan-outage-matrix`)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function createPlanOutage(values, token) {
  let body = { ...values };
  console.log(body);

  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/outage/create-plan-outage`, body, config)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getComplaintType() {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-complaint-type`)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getPlanOutage(token, pageIndex, pageSize) {
  console.log("tokentokentoken", token);
  let config = {
    headers: {
      Authorization: token,
    },
    params: {
      page: pageIndex,
      limit: pageSize,
    },
  };

  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/outage/get-plan-outage`, config)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getComplaintSubType(COMPLAINT_CODE, CONSUMER_CATEGORY) {
  console.log("parammm data", COMPLAINT_CODE, CONSUMER_CATEGORY);

  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/static/get-complaint-sub-type?COMPLAINT_CODE=${COMPLAINT_CODE}&CONSUMER_CATEGORY=${CONSUMER_CATEGORY}`
    )
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function submitComplaint(
  values,
  consumerDetails,
  dimDivisionData,
  token
) {
  console.log(
    "values,consumerDetails,dimDivisionDatavalues,consumerDetails,dimDivisionData",
    values,
    consumerDetails,
    dimDivisionData
  );

  let body = {
    SUBSTATION_CODE: dimDivisionData.SUBSTATION_CODE,
    FEEDER_CODE: dimDivisionData.FEEDER_CODE,
    COMPLAINT_MOBILE_NO: values.complainerMobNo,
    COMPLAINT_SOURCE_ADDRESS: consumerDetails.ADDRESS,
    CONSUMER_CATEGORY: values.consumerCategory,
    AC_ID: consumerDetails.AC_ID,
    LAST_BILL_DATE: values.lastBillDate,
    LAST_BILL_AMT: values.lastBillAmt == "" ? null : values.lastBillAmt,
    LAST_PAY_DATE: values.lastPayDate,
    LAST_PAY_AMT: values.lastPayAmt == "" ? null : values.lastPayAmt,
    CURRENT_ARREAR: values.arrear,
    HOW_LONG_SUPPLY_FAILED_IN_MINUTES: values.HOW_LONG_SUPPLY_FAILED_IN_MINUTES,
    SUPPLY_AVAILABLE_INMETER: values.SUPPLY_AVAILABLE_INMETER,
    SUPPLY_CABLE_CONDITION: values.SUPPLY_CABLE_CONDITION,
    CALLER_NAME: values.name,
    CONSUMER_ADDRESS: consumerDetails.ADDRESS,
    LANDMARK: values.landmark,
    PIN_CODE: values.pinCode == "" ? null : values.pinCode,
    DISTRICT: dimDivisionData.DISTRICT,
    COMPLAINT_REMARK: values.complaintDesc,
    COMPLAINT_CODE: values.complaintType,
    COMPLAINT_SUB_TYPE_CODE: values.complaintSubType,
    TICKET_TYPE: values.ticketType,
    FEED_BY: values.FEED_BY,
    COMPLAINT_FOR: values.COMPLAINT_FOR,
  };

  let config = {
    headers: {
      Authorization: token,
    },
  };

  console.log("submit data", body);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/complaint/complaint-register`, body, config)
      .then((response) => {
        console.log("data submitted successfully", response.data);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror", error);
        return rej(error)
      });
  });
}

export function billingData(AC_ID) {
  const body = {
    AC_ID: AC_ID,
    Act: "ConsumerOutstd",
    TICKET_ID: "",
  };
  console.log("bill json data", body);

  return new Promise((res, rej) => {
    Axios.post(
      `https://tripura.saielectricity.com/new_CODE/ConsumerOutstandingVipin.php`,
      // `http://45.114.141.173:9080/WEBSERVICE_MOBILE/MEPDCL_PAYMENT_COLLECTION/LIVE/ConsumerOutstandingVipin.php`,
      body
    )
      .then((response) => {
        console.log("response of billing data", response.data);
        return res(response.data);
      })
      .catch((error) => {
        console.log("error");
        rej(error);
      });
  });
}
export function submitAreaComplaint(values, token) {
  console.log(
    "values,consumerDetails,dimDivisionDatavalues,consumerDetails,dimDivisionData",
    values
  );

  let body = {
    AC_ID: values.accountId,
    CALLER_NAME: values.CALLER_NAME,
    COMPLAINT_MOBILE_NO: values.COMPLAINT_MOBILE_NO,
    CONSUMER_ADDRESS: values.CONSUMER_ADDRESS,
    LANDMARK: values.LANDMARK,
    SUBSTATION_CODE: values.SUBSTATION_CODE,
    FEEDER_CODE: values.FEEDER_CODE,
    CONSUMER_CATEGORY: values.CONSUMER_CATEGORY,
    COMPLAINT_CODE: values.COMPLAINT_CODE,
    COMPLAINT_SUB_TYPE_CODE: values.COMPLAINT_SUB_TYPE_CODE,
    PIN_CODE: values.PIN_CODE == "" ? null : values.PIN_CODE,
    DISTRICT: values.DISTRICT,
    COMPLAINT_REMARK: values.complaintDesc,
    SUPPLY_CABLE_CONDITION: values.SUPPLY_CABLE_CONDITION,
    SUPPLY_AVAILABLE_INMETER: values.SUPPLY_AVAILABLE_INMETER,
    HOW_LONG_SUPPLY_FAILED_IN_MINUTES: values.HOW_LONG_SUPPLY_FAILED_IN_MINUTES,
    FEED_BY: values.FEED_BY,
    COMPLAINT_FOR: values.COMPLAINT_FOR,
    TICKET_TYPE: values.TICKET_TYPE,
  };

  let config = {
    headers: {
      Authorization: token,
    },
  };

  console.log("submit data", body);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/complaint/complaint-register`, body, config)
      .then((response) => {
        console.log("data submitted successfully", response.data);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror", error);
        return rej(error)

      });
  });
}

export function getComplaints(
  token,
  complaintType,
  pageIndex,
  pageSize,
  fromDate,
  toDate
) {
  console.log("tokentokentoken", token);
  let config = {
    headers: {
      Authorization: token,
    },
    params: {
      page: pageIndex,
      limit: pageSize,
      fromDate: fromDate,
      toDate: toDate,
    },
  };

  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/complaint/get-complaints?complaintType=${complaintType}`,
      config
    )
      .then((response) => {
        console.log("response", response.data);
        return res(response.data);
      })
      .catch((error) => {
        console.log("error");
        rej(error);
      });
  });
}

export function getUserDetails() {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/complaint/get-user-detail`).then((response) => {
      console.log("response", response.data);
      return res(response.data.data);
    });
  }).catch((error) => {
    console.log("error");
  });
}

export function getComplaintTypeSubType(comSubTypeCode) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/complaint/get-complaint-type-sub-type/${comSubTypeCode}`
    ).then((response) => {
      console.log("response", response.data);
      return res(response.data.data);
    });
  }).catch((error) => {
    console.log("error");
  });
}

export function getConsumerDataByAccId(AC_ID, mobileNO, billNO) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/consumer/search-consumer?AC_ID=${AC_ID}&MOBILE_NO=${mobileNO}&billNO=${billNO}`
    ).then((response) => {
      console.log("response", response.data);
      return res(response.data.data);
    });
  }).catch((error) => {
    console.log("error");
  });
}

export function getDimDivisionData(SUBSTATION_CODE, FEEDER_CODE) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/static/get-division-data-by-div-code/${SUBSTATION_CODE}/${FEEDER_CODE}`
    ).then((response) => {
      console.log("response", response.data);
      return res(response.data.data);
    });
  }).catch((error) => {
    console.log("error");
  });
}

export function getPlanOutageBySubStationFeeder(SUBSTATION_CODE, FEEDER_CODE) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/static/get-plan-out-by-subStation-Feeder/${SUBSTATION_CODE}/${FEEDER_CODE}`
    ).then((response) => {
      console.log("response of plan ou by subst feeder", response.data);
      return res(response.data.data);
    });
  }).catch((error) => {
    console.log("error");
  });
}

export function sendOtp(mobileNo) {
  console.log("mobb getOtp", mobileNo);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/static/getOtp`, mobileNo).then((response) => {
      console.log("response", response.data);
      return res(response.data);
    });
  }).catch((error) => {
    console.log("error ", error);
  });
}

export function validateOtp(body) {
  console.log("validation dataaa", body);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/static/validate-otp`, body).then((response) => {
      console.log("response", response.data);
      return res(response.data);
    });
  }).catch((error) => {
    console.log("error", error);
  });
}

export function getRemarksAction() {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-remarks-actions`).then((response) => {
      console.log("getRemarkAction", response);
      return res(response.data);
    });
  }).catch((error) => {
    console.log("errorrr", error);
  });
}

export function getCrewBySubStation(SUBSTATION_CODE) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/crew/get-crew-members-by-substation/${SUBSTATION_CODE}`
    ).then((response) => {
      console.log("getcrew", response);
      return res(response.data);
    });
  }).catch((error) => {
    console.log("errorrr", error);
  });
}

export function submitComplaintFollowUp(values, token) {
  console.log("tttt", token);
  let config = {
    headers: {
      Authorization: token,
    },
  };

  console.log("submit data", values);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/complaint/update-fact-follow-up`, values, config)
      .then((response) => {
        console.log("data submitted successfully", response.data);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror", error);
      });
  });
}

export function assignCrew(values, token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  console.log("tokkkeen", token);
  console.log("submit data", values);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/crew/assign-crew-members`, values, config)
      .then((response) => {
        console.log("data submitted successfully", response.data);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror", error);
      });
  });
}

export function getMembersByTicket(TICKET_ID) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/get-users-assign-with-ticket?TICKET_ID=${TICKET_ID}`
    ).then((response) => {
      console.log("response", response.data);
      return res(response.data.data);
    });
  }).catch((error) => {
    console.log("error");
  });
}

export function getUserAssignWithByTicket(TICKET_ID, token) {
  console.log("tttt", TICKET_ID);
  let config = {
    headers: {
      Authorization: token,
    },
  };

  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/user/get-users-assign-with-ticket?TICKET_ID=${TICKET_ID}`,
      config
    ).then((response) => {
      console.log("response", response.data);
      return res(response.data.data);
    });
  }).catch((error) => {
    console.log("error");
  });
}

export function getFollowUpData(token) {
  console.log("tokentokentoken", token);
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/complaint/get-follow-up-details`, config)
      .then((response) => {
        console.log("response", response.data);
        return res(response.data.data);
      })
      .catch((error) => {
        console.log("error");
        rej(error);
      });
  });
}

export function getAssignedCrew(token) {
  console.log("tokentokentoken", token);
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/crew/get-follow-table-details`, config)
      .then((response) => {
        console.log("asss crewww", response.data);
        return res(response.data.data);
      })
      .catch((error) => {
        console.log("error");
        rej(error);
      });
  });
}

export function searchForSubstation(searchTerm) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/outage/search-for-substation-in-plan-outage?SUBSTATION_NAME=${searchTerm}`
    )
      .then((response) => {
        console.log("asss crewww", response.data);
        return res(response.data.data);
      })
      .catch((error) => {
        console.log("error");
        rej(error);
      });
  });
}

export function getPlanOutageDetForDepartment(token) {
  console.log("tokentokentoken", token);
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/outage/get-user-fact-area-details`, config)
      .then((response) => {
        console.log("response", response.data);
        return res(response.data.data);
      })
      .catch((error) => {
        console.log("error");
        rej(error);
      });
  });
}

export function deactivateUser(token, user_id) {
  console.log("tokentokentoken", token);
  let config = {
    headers: {
      Authorization: token,
    },
  };
  const body = {
    user_id: user_id,
  };
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/user/deactivate-user`, body, config)
      .then((response) => {
        console.log("response", response.data);
        return res(response.data.data);
      })
      .catch((error) => {
        console.log("error");
        rej(error);
      });
  });
}

export function submitSupportForm(values) {
  console.log("submit data", values);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/support/submit-support`, values)
      .then((response) => {
        console.log(" submitted data resss", response.data);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror", error);
      });
  });
}

export function getSupportData() {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/support/get-support-data`)
      .then((response) => {
        console.log("response", response.data);
        return res(response.data.data);
      })
      .catch((error) => {
        console.log("error");
        rej(error);
      });
  });
}
