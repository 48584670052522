import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import portalConfig from "../configurations/oms.configs";
import { useSelector } from "react-redux";

const ProtectedRoute = () => {
  const token = useSelector((state) => state.auth.token);

  const userDetail = useSelector((state) => state.auth.userDetails);
  console.log("sidebar user det", userDetail);

  let authenticated = token;

  //  let authenticated = true;
  const { unAuthenticateEntry, authenticateEntry, authenticateEntryForAdmin } =
    portalConfig;
  console.log("private route", unAuthenticateEntry);

  if (!authenticated) {
    return <Navigate to={`${unAuthenticateEntry}`} />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
