import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
//import store from './store';
import AppLayout from './layout/appLayout';
import {store , persistor} from './store/index'
import { PersistGate } from 'redux-persist/integration/react';


function App() {

  
  return(
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <AppLayout />
      </Provider>
    </PersistGate>
    
  )
}

export default App;
