import React, { Suspense, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import omsPrivateRoute from "../route/oms.private.route";
import omsPublicRoute from "../route/oms.public.route";
import ProtectedRoute from "../route/ProtectedRoute";
import PublicRoute from "../route/PublicRoute";
import { jwtDecode } from "jwt-decode";
import {
  ShimmerButton,
  ShimmerContentBlock,
  ShimmerSocialPost,
} from "react-shimmer-effects";
import { useSelector } from "react-redux";
import useAuth from "../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";

const AppRoute = () => {
  const { signOut } = useAuth();

  const userDetails = useSelector((state) => state.auth.userDetails);

  console.log("userrr detailssss", userDetails);
  const USER_TYPE = userDetails?.USER_TYPE;

  const token = useSelector((state) => state.auth.token);

  console.log("stage token", token);

  let decodedToken, expireTime;

  // if (token) {
  //   decodedToken = jwtDecode(token);
  //   expireTime = decodedToken.exp - decodedToken.iat;
  //   expireTime = expireTime * 1000;
  //   // console.log("tokentokentokentokentokentokentokentoken from view.js",(decodedToken.exp - decodedToken.iat))
  // }

  console.log("expireTimeexpireTime", expireTime);
  const showLoginError = () => {
    toast.error("Your session is expired please login again", {
      position: "top-right",
    });
  };

  let currExecTime = new Date().getTime();

  let finalTime = localStorage.getItem("tokenExpTime");
  finalTime = Number(finalTime);
  let execTime = finalTime - currExecTime;

  const handleLogout = () => {
    console.log("Logging out", token);
    if (token) {
      signOut();
      setTimeout(() => {
        showLoginError();
      }, 500);
    }
  };

  useEffect(() => {
    console.log("finalTime", finalTime, currExecTime, finalTime - currExecTime);

    if (finalTime > 0) {
      console.log("inside useefefect");
      let getData = setTimeout(() => {
        handleLogout();
      }, execTime);
      return () => clearTimeout(getData);
    }
  }, [execTime]);

  // useEffect(() => {
  //   if (execTime > 10) {
  //     const timeoutId = setTimeout(handleLogout, expireTime);
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [execTime]);

  return (
    <>
      <Suspense
        fallback={
          <div>
            <ShimmerSocialPost type="both" />
          </div>
        }
      >
        <ToastContainer />

        <Routes>
          <Route path="/" element={<ProtectedRoute />}>
            {omsPrivateRoute.map((route, ind) => {
              if (route.role.includes(USER_TYPE)) {
                return (
                  <Route
                    key={route.uniqueKey + ind}
                    path={route.pathName}
                    element={<route.componentName />}
                  />
                );
              }
            })}
          </Route>
          <Route path="/" element={<PublicRoute />}>
            {omsPublicRoute.map((route, ind) => {
              return (
                <Route
                  key={route.uniqueKey + ind}
                  path={route.pathName}
                  element={<route.componentName />}
                />
              );
            })}
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

const View = () => {
  return (
    <>
      <AppRoute />
    </>
  );
};

export default View;
