import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name:"auth",
    initialState:{
        token:"",
        setUserDet:{},
        isSignedIn:false,
        userAreaDetails:""
    },
    reducers: {
        OnSignIn : (state,action)=>{
           state.isSignedIn  = true
           state.token = action.payload
           
        },
        onSignOut : (state, action) =>{
          state.isSignedIn = false
          state.token = ""
        },
        setToken : (state,action)=>{
            state.token = action.payload
        },
        setUserDet:(state,action)=>{
            state.userDetails = action.payload
        },
        setUserAreaDet:(state,action)=>{
            state.userAreaDetails = action.payload
        }
    }

})


// export default authSlice.reducer
export const { OnSignIn, onSignOut, setToken ,setUserDet,setUserAreaDet} = authSlice.actions

export default authSlice.reducer