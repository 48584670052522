import React from "react";
import useAuth from "../../../hooks/useAuth";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

function UserProfile({ toggleProfile }) {
  const { signOut, isSignedIn } = useAuth();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);
  const decodedToken = jwtDecode(token);

  const handleUserDetail = async () => {
    console.log("redirect to user details");
    await toggleProfile();
    navigate("/user-detail");
  };

  const handleLogout = async (values) => {
    try {
      const res = await signOut(values);
      //  console.log('after signout', isSignedIn)
      //  console.log('after signout token ', token)
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="max-w-lg w-64 grid  bg-white shadow-md rounded-sm overflow-hidden my-4">
        <div className=" px-6 py-1 border-2 border-[#DADADA] rounded-t-sm">
          <div className="flex flex-row items-center justify-center text-xl">
            <button
              type="button"
              className="absolute right-2"
              onClick={handleUserDetail}
            >
              <HiArrowNarrowRight />
            </button>
            <div className="flex  justify-center font-semibold ">
              User Details
            </div>
          </div>
          <div>
            {/* <p className="text-gray-700 text-base">
          <strong>Email:</strong> abc
        </p>
        <p className="text-gray-700 text-base">
          <strong>Designation:</strong> ee
        </p> */}
          </div>
        </div>
        <div className="flex flex-col gap-2 m-4">
          <p className="text-gray-700 text-base">
            <strong>UserId:</strong> {decodedToken.user_id}
          </p>
          <p className="text-gray-700 text-base">
            <strong>UserName:</strong> {decodedToken.USER_NAME}
          </p>

          <p className="text-gray-700 text-base">
            <strong>Designation:</strong> {decodedToken.DESG}
          </p>
        </div>
        <div className="flex justify-center px-6 py-4 mt-4">
          <button
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-6 rounded"
          >
            Log Out
          </button>
        </div>
      </div>
    </>
  );
}

export default UserProfile;
