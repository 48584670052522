import React from 'react'



const portalConfig = {
    authenticateEntryForAdmin:"/user-approval",
    authenticateEntry:"/complaints",
    unAuthenticateEntry:"/sign-in"
}

export default portalConfig
