import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import portalConfig from "../configurations/oms.configs";
import { useSelector } from "react-redux";

const PublicRoute = () => {
  // let authenticated = false;
  // let authenticated = false;

  const token = useSelector((state) => state.auth.token);
  const userDetail  = useSelector(state => state.auth.userDetails)
  console.log('sidebar user det' , userDetail)

  let authenticated = token;


  const { authenticateEntry , authenticateEntryForAdmin } = portalConfig;

  console.log("public route", authenticateEntry);
  return authenticated ?  ((userDetail?.DESG === 'SUPERADMIN' ?
  (<Navigate to={`${authenticateEntryForAdmin}`} />) : (<Navigate to={`${authenticateEntry}`} />) ) ): <Outlet />;
};

export default PublicRoute;
